<template>
  <div class="scan-filter-wrapper">
    <button class="scan-filter-btn" @click="showSelectFilter" v-show="!isShowSelectFilter">
      <i class="fas fa-sliders-h"></i>
    </button>
    <div class="select-filter-wrapper" v-show="isShowSelectFilter">
      <div class="box">
        <header>
          Filtros
          <button type="button" class="close text-dark" @click="hideSelectFilter">
            <img src="@/assets/images/icon/close.svg" alt="fechar"/>
          </button>
        </header>
        <div class="content">
          <div class="label">Tags</div>
          <div class="tags-select-box">
            <v-select multiple :options="filterOptions" label="text" v-model="tagsSelected"/>
          </div>
          <div class="button-box">
            <button type="button" class="scan-btn scan-btn-outline" @click="limparFiltro">LIMPAR FILTROS</button>
          </div>
          <button type="button" class="scan-btn" @click="aplicarFiltro">APLICAR FILTROS</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import vSelect from 'vue-select'
import { CHECK_IN_MODULE } from '@/modules/check-in'

export default {
  name: 'scan-filter',
  components: { vSelect },
  data: () => ({
    isShowSelectFilter: false,
    tagsSelected: null
  }),
  computed: {
    ...mapState(CHECK_IN_MODULE, {
      filterOptions: state => state.tags.list
    })
  },
  methods: {
    ...mapActions(CHECK_IN_MODULE, ['loadTagsList']),
    hideSelectFilter () {
      this.isShowSelectFilter = false
    },

    showSelectFilter () {
      this.isShowSelectFilter = true
    },

    limparFiltro () {
      this.tagsSelected = null
      this.aplicarFiltro()
    },

    aplicarFiltro () {
      const tagsSelecionadas = [...this.tagsSelected ?? []]
      this.$emit('filter', tagsSelecionadas.map(tag => tag.id))
      this.hideSelectFilter()
    }
  },
  beforeMount () {
    this.loadTagsList()
  }
}
</script>

<style lang="scss">
@import "~vue-select/src/scss/vue-select.scss";
</style>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "src/design/variables";

.scan-filter-wrapper {
}

.scan-filter-btn {
  position: absolute;
  @media (min-width: map-get($grid-breakpoints, "md")) {
    top: 63px;
    right: 68px;
  }
  top: 23px;
  right: 21px;
  background-color: #564AB1;
  color: #fff;
  border: 0;
  border-radius: 7px;
  display: grid;
  place-items: center;
  font-size: 24px;
  padding: 18px 19px;
  box-shadow: 0 4px 4px 0 #00000040;
}

.select-filter-wrapper {
  position: absolute;
  width: 100%;

  .box {
    @media (min-width: map-get($grid-breakpoints, "md")) {
      --size-width: 450px;
      --size-height: 400px;
      --size-top: 240px;
      --size-margin: auto;
    }
    --size-width: auto;
    --size-height: auto;
    --size-top: 149px;
    --size-margin: 8px;

    width: var(--size-width);
    height: var(--size-height);
    overflow-y: auto;
    margin: var(--size-top) var(--size-margin) 0;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 20px 18px;
  }

  header {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #292F42;
    margin-bottom: 10px;

    .close {
      opacity: 1;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 400;
    color: #292F42;
    margin-bottom: 8px;
  }

  .tags-select-box {
    margin-bottom: 32px;
  }

  .button-box {
    margin-bottom: 8px;
  }

  button.scan-btn {
    display: block;
    width: 100%;
    border: 2px solid transparent;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding: 12px;
    background-color: #292F42;
    color: #fff;
    &.scan-btn-outline {
      background-color: #fff;
      color: #292F42;
      border-color: #292F42;
    }
  }
}

</style>
