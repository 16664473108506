<template>
  <div class="page-container">
    <div class="page-content">
      <scan-reader :on-read="(data) => validCode(data)"/>
      <scan-filter v-show="!onReady" @filter="(data) => setFilter(data)"/>
      <scan-result ref="result" :filter="filterTags"/>
    </div>
    <div class="page-footer">
      <router-link class="text" :to="{name: routeHomeName}">
        <i class='icon bx bx-home'></i>
        Home
      </router-link>
    </div>
  </div>
</template>

<script>
import { CHECK_IN_PAGE_INDEX } from '@/modules/check-in/routes'
import ScanReader from '@/modules/check-in/pages/scan/scan-reader'
import ScanFilter from '@/modules/check-in/pages/scan/scan-filter'
import ScanResult from '@/modules/check-in/pages/scan/scan-result'

export default {
  name: 'scan',
  components: { ScanResult, ScanFilter, ScanReader },
  data: () => ({
    routeHomeName: CHECK_IN_PAGE_INDEX,
    filterTags: [],
    onReady: false
  }),
  methods: {
    setFilter (filter) {
      this.filterTags = filter
    },

    async validCode (code) {
      this.onReady = true
      await this.$refs.result.validateCode(code)
      this.onReady = false
    }
  },
  mounted () {
    this.$store.dispatch('event/LoadOnceSetting')
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #E5E5E5;
  display: flex;
  flex-direction: column;
}

.page-content {
  flex: 1;
  padding: 0;
  position: relative;
}

.page-footer {
  background-color: #292F42;
  display: grid;
  place-items: center;
  min-height: 64px;

  .text {
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 300;

    .icon {
      font-size: 32px;
    }
  }
}
</style>
