<template>
  <qrcode-stream
    :camera="camera"
    :track="paintBoundingBox"
    @init="onInit"
    @decode="onDecode"
    class="qr-code-reader qr-code-reader-absolute"
  >
    <div class="loading-indicator" v-if="!ready">
      Permita acesso a sua camêra
      <img src="@/assets/images/check-in/acesso-readqrcode.png" alt="arte-permitir-acesso">
    </div>
    <template v-else>
      <div v-if="isReading" class="validation-pending">
        Validando...
      </div>
      <div class="reader-animation" :class="{'reader-animation-stop': isReading}">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </template>
  </qrcode-stream>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'scan-reader',
  components: { QrcodeStream },
  props: {
    onRead: Function
  },
  data: () => ({
    isReading: false,
    ready: false,
    camera: 'auto',
    result: null
  }),
  methods: {
    async onInit (promise) {
      try {
        await promise
        this.ready = true
      } catch (error) {
        console.error(error)
      }
    },

    paintBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },

    async onDecode (content) {
      this.result = content
      this.turnCameraOff()
      await this.onRead(content)
      this.turnCameraOn()
    },

    turnCameraOn () {
      this.camera = 'auto'
      this.isReading = false
    },

    turnCameraOff () {
      this.isReading = true
      this.camera = 'off'
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-indicator {
  padding-top: 12vh;
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  img {
    margin-top: 35px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
}

.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.qr-code-reader.qr-code-reader-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.reader-animation {
  $espessura: 2%;
  $comprimento: 34%;

  --color: rgb(86, 74, 177);
  min-width: 200px;
  min-height: 200px;
  width: 40vmin;
  height: 40vmin;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  div:nth-child(-n + 4) {
    position: absolute;
    width: 100%;
    height: 100%;

    &::before, &::after {
      top: 0;
      left: 0;
      position: absolute;
      content: '';
      display: block;
      width: $comprimento;
      height: $espessura;
      background-color: var(--color);
      border-radius: 100vmax;
    }

    &::after {
      height: $comprimento;
      width: $espessura;
    }
  }

  div:nth-child(2) {
    transform: rotate(90deg);
  }

  div:nth-child(3) {
    transform: rotate(180deg);
  }

  div:nth-child(4) {
    transform: rotate(270deg);
  }

  div:nth-child(5) {
    width: 80%;
    margin-left: 10%;
    height: $espessura;
    background-color: var(--color);
    border-radius: 100vmax;
    margin-top: 10%;
    animation: 0.8s linear 0s infinite alternate scanning;
    @keyframes scanning {
      from {
        margin-top: 10%;
      }
      to {
        margin-top: 90%;
      }
    }
  }

  &.reader-animation-stop div:nth-child(5) {
    animation-play-state: paused;
  }
}
</style>
