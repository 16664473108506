import http from '@/http'

export type Response = {
  isQrCodeThrowable?: true
  isFilterTagThrowable?: true
  isCheckInSuccess?: true
  isCheckInAlready?: true
  message: string,
  id?: string
  name?: string
}

export async function putCheckInQrCode (qrCodeData: string, tagFilterList: number[]): Promise<Response> {
  const response = await http.post('/admin/check-in/qr-code', {
    confirmation_code: qrCodeData,
    tags: tagFilterList
  }, {
    validateStatus: () => true
  })
  const { data } = response
  if (response.status === 422) {
    return handleRespondeFail(data.message, data.participant ?? null)
  }
  if (response.status === 200) {
    return handleRespondeSuccess(data.message, data.participant)
  }
  const exc: any = { CODID: 'V8S0N20U', response }
  exc['error'] = new Error(exc.CODID)
  throw exc
}

function handleRespondeFail (message: string, participant?: {id: number, name: string}): Response {
  if (!participant) {
    return {
      isQrCodeThrowable: true,
      message
    }
  }
  if (message === 'já fez o check-in') {
    return {
      isCheckInAlready: true,
      message,
      name: participant.name,
      id: '' + participant.id
    }
  }
  return {
    isFilterTagThrowable: true,
    message,
    name: participant.name,
    id: '' + participant.id
  }
}

function handleRespondeSuccess (message: string, participant: {id: number, name: string}): Response {
  return {
    isCheckInSuccess: true,
    message,
    name: participant.name,
    id: '' + participant.id
  }
}
