<template>
  <div class="box-result" v-show="!isStatusInitial">
    <div class="wrap-content">
      <div class="content-result content-result-red" v-show="isCodeAlready">
        <img src="@/assets/images/icon/caution-sign.png" class="icon" alt=""> <br>
        <b>{{ userRead.nome }}</b> já fez o check-in
      </div>
      <div class="content-result content-result-yellow" v-show="isCodeCheckOut">
        <img src="@/assets/images/icon/log-out-sign.png" class="icon" alt=""> <br>
        <b>{{ userRead.nome }}</b> fez o check-out com sucesso
      </div>
      <div class="content-result content-result-red" v-show="isCodeInvalid">
        <img src="@/assets/images/icon/caution-sign.png" class="icon" alt=""> <br>
        Este código QR não está associado a um convidado confirmado ou é inválido.
      </div>
      <div class="content-result content-result-red" v-show="isCodeTagInvalid">
        <i class="far fa-times-circle icon"></i> <br>
        <b>{{ userRead.nome }}</b> {{ messageRead }}
      </div>
      <div class="content-result content-result-green" v-show="isCodeCheckIn">
        <img src="@/assets/images/icon/ok-sign.png" class="icon" alt=""> <br>
        <b>{{ userRead.nome }}</b> fez o check-in com sucesso
      </div>
      <div class="button-box" v-show="isCodeAlready || (isCodeCheckIn && isCheckOutEnabled)">
        <button type="button" class="scan-btn scan-btn-caution" @click="fazerCheckOut">
          Check-out
        </button>
      </div>
      <div class="button-box" v-show="isCodeAlready">
        <button type="button" class="scan-btn scan-btn-success" @click="fazerCheckIn">
          Check-in neste terminal
        </button>
      </div>
      <div class="button-box" v-show="!isCodeInvalid">
        <button type="button" @click="navToItem(userRead.id)" class="scan-btn scan-btn-outline">
          Ver detalhes do usuário
        </button>
      </div>
      <div class="button-box">
        <button type="button" class="scan-btn" @click="finish">
          <img src="@/assets/images/icon/qr-code-scan.png" class="icon" alt="">
          Próxima leitura
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { CHECK_IN_PAGE_INFO } from '@/modules/check-in/routes'
import { putCheckInQrCode } from '@/modules/check-in/service/putCheckInQrCode'
import { errorMessage } from '@/components/alertsMessages'
import { mapGetters } from 'vuex'
import { putManualCheckOut } from '@/modules/check-in/service/putManualCheckOut'
import { putManualCheckIn } from '@/modules/check-in/service/putManualCheckIn'
import { CHECK_IN_MODULE } from '@/modules/check-in'

const STATUS = {
  INITIAL: Symbol('INITIAL'),
  INVALID: Symbol('INVALID'),
  CHECK_IN: Symbol('CHECK_IN'),
  ALREADY: Symbol('ALREADY'),
  CHECK_OUT: Symbol('CHECK_OUT'),
  TAG_INVALID: Symbol('TAG_INVALID')
}

export default {
  name: 'scan-result',
  props: {
    filter: Array
  },
  data: () => ({
    userRead: {
      id: '',
      nome: ''
    },
    messageRead: '',
    status: STATUS.INITIAL,
    readPromiseResolve: null,
    routeInfo: CHECK_IN_PAGE_INFO
  }),
  computed: {
    ...mapGetters('event', ['isCheckOutEnabled']),
    isStatusInitial () {
      return this.status === STATUS.INITIAL
    },
    isCodeInvalid () {
      return this.status === STATUS.INVALID
    },
    isCodeCheckIn () {
      return this.status === STATUS.CHECK_IN
    },
    isCodeAlready () {
      return this.status === STATUS.ALREADY
    },
    isCodeCheckOut () {
      return this.status === STATUS.CHECK_OUT
    },
    isCodeTagInvalid () {
      return this.status === STATUS.TAG_INVALID
    }
  },
  methods: {
    navToItem (id) {
      this.$store.commit(CHECK_IN_MODULE + '/setShowDetailId', id)
    },
    async validateCode (code) {
      this.status = STATUS.INITIAL
      let resp
      try {
        resp = await putCheckInQrCode(code, this.filter)
      } catch {
        errorMessage('Ouve um problema para ler esse QR Code')
        this.status = STATUS.INITIAL
        return
      }
      this.messageRead = resp.message
      if (resp.isQrCodeThrowable) {
        this.status = STATUS.INVALID
      } else {
        this.userRead.id = resp.id
        this.userRead.nome = resp.name
      }
      if (resp.isCheckInSuccess) {
        this.status = STATUS.CHECK_IN
      }
      if (resp.isFilterTagThrowable) {
        this.status = STATUS.TAG_INVALID
      }
      if (resp.isCheckInAlready) {
        this.status = STATUS.ALREADY
      }
      return new Promise(resolve => {
        this.readPromiseResolve = resolve
      })
    },

    fazerCheckOut () {
      this.status = STATUS.CHECK_OUT
      putManualCheckOut(this.userRead.id)
    },

    fazerCheckIn () {
      this.status = STATUS.CHECK_IN
      putManualCheckIn(this.userRead.id, this.filter)
    },

    finish () {
      this.status = STATUS.INITIAL
      if (this.readPromiseResolve) {
        this.readPromiseResolve(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "src/design/variables";

.box-result {
  width: 100%;
  position: absolute;
  @media (min-width: map-get($grid-breakpoints, "md")) {
    --size-width: 450px;
    --size-height: 400px;
    --size-top: 240px;
    --size-margin: auto;
  }
  --size-width: auto;
  --size-height: auto;
  --size-top: 149px;
  --size-margin: 8px;

  top: var(--size-top);

  .wrap-content {
    width: var(--size-width);
    height: var(--size-height);
    overflow-y: auto;
    margin: 0 var(--size-margin);
    background: #FFFFFF;
    border-radius: 5px;
    padding: 20px 18px;
  }
}

.button-box {
  margin-bottom: 8px;
}

.scan-btn {
  display: flex;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  padding: 12px;
  background-color: #292F42;
  color: #fff;

  &.scan-btn-outline {
    background-color: #fff;
    color: #292F42;
    border-color: #292F42;
  }

  &.scan-btn-caution {
    background-color: #F1B44C;
  }

  &.scan-btn-success {
    background-color: #09A96F;
  }

  img.icon {
    width: 21px;
    margin-right: 16px;
  }
}

.content-result {
  padding: 20px 15px;
  border-radius: 5px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 300;
  text-align: center;

  b {
    font-weight: 600;
  }

  &.content-result-red {
    background: #E3414B;
    color: #ffffff;
  }

  &.content-result-green {
    background: #09A96F;
    color: #ffffff;
  }

  &.content-result-yellow {
    background: #F1B44C;
    color: #ffffff;
  }

  img.icon {
    margin-bottom: 11px;
    display: inline-block;
  }

  i.icon {
    margin-bottom: 11px;
    font-size: 40px;
  }
}
</style>
